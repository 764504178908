import React from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { Formik, Form, FastField, ErrorMessage } from 'formik';
import Recaptcha from 'react-google-recaptcha';
import * as Yup from 'yup';
import { Button, Input } from '../../common';
import { Error, Center, InputField } from './styles';

export const Contact = () => (
  <div id="contact" className="section">
    <h2 className="mb-5 font-weight-bolder title">Contact</h2>
    <Formik
      initialValues={{
        name: '',
        email: '',
        message: '',
        recaptcha: '',
        success: false,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Full name field is required'),
        email: Yup.string()
          .email('Invalid email')
          .required('Email field is required'),
        message: Yup.string().required('Message field is required'),
        recaptcha: Yup.string().required('Robots are not welcome yet!'),
      })}
      onSubmit={async ({ name, email, message }, { setSubmitting, resetForm, setFieldValue }) => {
        try {
          await axios({
            method: 'POST',
            url: `${atob(process.env.REACT_APP_FORMIK_ENDPOINT)}`,
            headers: {
              'Content-Type': 'application/json',
            },
            data: JSON.stringify({
              name,
              email,
              message,
            }),
          });
          setSubmitting(false);
          setFieldValue('success', true);
          setTimeout(() => resetForm(), 6000);
        } catch (err) {
          setSubmitting(false);
          setFieldValue('success', false);
          alert('Something went wrong, please try again!') // eslint-disable-line
        }
      }}
    >
      {({ values, touched, errors, setFieldValue, isSubmitting }) => (
        <Form>
          <InputField>
            <Input
              as={FastField}
              type="text"
              name="name"
              component="input"
              aria-label="name"
              placeholder="Full name *"
              error={touched.name && errors.name}
            />
            <ErrorMessage component={Error} name="name" />
          </InputField>
          <InputField>
            <Input
              id="email"
              aria-label="email"
              component="input"
              as={FastField}
              type="email"
              name="email"
              placeholder="Email *"
              error={touched.email && errors.email}
            />
            <ErrorMessage component={Error} name="email" />
          </InputField>
          <InputField>
            <Input
              as={FastField}
              component="textarea"
              aria-label="message"
              id="message"
              rows="8"
              type="text"
              name="message"
              placeholder="Message *"
              error={touched.message && errors.message}
            />
            <ErrorMessage component={Error} name="message" />
          </InputField>
          {values.name && values.email && values.message && (
            <InputField>
              <FastField
                component={Recaptcha}
                sitekey={atob(process.env.REACT_APP_RECAPTCHA_KEY)}
                name="recaptcha"
                onChange={value => setFieldValue('recaptcha', value)}
              />
              <ErrorMessage component={Error} name="recaptcha" />
            </InputField>
          )}
          {values.success && (
            <InputField>
              <Center>
                <Alert variant="success">
                  Your message has been successfully sent, I will get back to you ASAP!
                </Alert>
              </Center>
            </InputField>
          )}
          <Center>
            <Button secondary type="submit" disabled={isSubmitting}>
              Submit
          </Button>
          </Center>
        </Form>
      )}
    </Formik>
  </div>
);

export default Contact;