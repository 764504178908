module.exports = {
    defaultTitle: 'Guillaume ELAMBERT • Professional Portfolio of an Engineer',
    logo: '/memoji-smile.png',
    author: 'Guillaume ELAMBERT',
    url: 'https://elambert.guillau.me/',
    legalName: 'Guillaume ELAMBERT',
    defaultDescription: 'Hi there ! I’m Guillaume ELAMBERT and this is my professional portfolio. I’m an engineering student looking for summer job so please feel free contacting me !',
    socialLinks: {
        twitter: 'https://twitter.com/SchwiftyTracto',
        github: 'https://github.com/guillaume-elambert',
        linkedin: 'https://linkedin.com/in/guillaume-elambert/',
        instagram: 'https://instagram.com/guillaume.elambert/',
        facebook: 'https://facebook.com/guillaume.elambert/',
    },
    social: {
        facebook: '400486240510654',
        twitter: '@SchwiftyTracto',
    },
    address: {
        city: 'Auffargis',
        region: 'Yvelines',
        country: 'France',
        zipCode: '78610',
    },
    contact: {
        email: 'guillaume.elambert@yahoo.fr',
        phone: '+33769674626',
    },
    foundingDate: '2021',
};